import { environment as defaultEnvironment } from './environment.defaults';
  export const environment = {
    ...defaultEnvironment,
    production: true,
    apiPath: 'https://gsonline-api.kpvsolutions.com/api/v3/',
    log: false,
    flags: {
      useNewHeader: false
   }
};
